<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import Phone1 from '@/images/landing-page/scorpio/io/phone1.png'
  import Phone2 from '@/images/landing-page/scorpio/io/phone2.png'
  import Phone3 from '@/images/landing-page/scorpio/io/phone3.png'
  import Phone4 from '@/images/landing-page/scorpio/io/phone4.png'
  import Phone5 from '@/images/landing-page/scorpio/io/phone5.png'

  const images = [
    {
      img: Phone1,
      title: 'Confirmed Fix & Cost',
      description:
        'Never overpay for repairs. FIXD gives you the most likely fix for your car and how much it should cost.',
    },
    {
      img: Phone2,
      title: 'Diagnose Car Problems in Seconds',
      description:
        "Most mechanics charge $150 to diagnose a single check engine light. With FIXD's detailed Diagnostic Report, you can quickly and accurately diagnose car problems yourself, saving time and money.",
    },
    {
      img: Phone3,
      title: "Know If It's Safe to Drive",
      description:
        "Wondering if it's safe to keep driving with your check engine light? Find out in seconds with Issue Severity.",
    },
    {
      img: Phone4,
      title: 'Clear Your Check Engine Light',
      description:
        "You can turn off your check engine light from your phone if it's nothing serious or you're troubleshooting repairs.",
    },
    {
      img: Phone5,
      title: 'Never Miss an Oil Change',
      description:
        'Prevent costly repairs and keep your car running smoothly with automatic maintenance alerts that let you know when your car is due for service!',
    },
  ]
</script>

<style>
  .color-container {
    background: linear-gradient(to right, rgb(90 107 125), rgb(81 97 107));
  }
</style>

<Section
  center="true"
  maxWidth="2xl"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 md:px-16 color-container">
    <div class="mb-10">
      <h1 class="text-white">Other Ways FIXD Helps You Save</h1>
    </div>

    <Carousel
      showPagination={true}
      showArrows={true}
      loop={true}
      splideOptions={{
        autoHeight: true,
        lazyLoad: true,
      }}
    >
      {#each images as image}
        <CarouselItem>
          <div class="text-center">
            <img src={image.img} alt={image.title} loading="lazy" />
            <div
              class="text-xl text-white leading-tight mt-10 mx-auto w-full md:w-4/5"
            >
              <p class="font-bold">{image.title}</p>
              <p class="font-medium">
                {image.description}
              </p>
            </div>
          </div>
        </CarouselItem>
      {/each}
    </Carousel>
  </div>
</Section>
