<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import { Clipboard, Phone, Engine } from '@/components/icons'
</script>

<Section
  center="true"
  maxWidth="2xl"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 bg-grey-200 md:px-16">
    <div class="mb-7">
      <h1 class="font-extrabold mb-6">What is FIXD?</h1>

      <p class="text-xl font-medium leading-tight md:px-20">
        FIXD is a car repair scanner that translates over 7,000 check engine
        light codes into plain English on your smartphone. The FIXD app gives
        you detailed, accurate information about your car problem and other
        tools to help you save money and feel confident at the repair shop!
      </p>
    </div>

    <div class="mt-20">
      <h1 class="font-extrabold mb-20">
        Enjoy <span class="text-green">$230+</span> In Instant Savings
      </h1>
      <div class="flex flex-col md:flex-row mb-7 gap-10 text-xl">
        <div
          class="text-center mx-6 my-1 md:m-2.5 leading-tight flex flex-col items-center"
        >
          <div
            class="rounded-full size-40 bg-white flex items-center justify-center mb-6"
          >
            <Engine color="green" class="size-28" />
          </div>
          <p class="font-extrabold mb-6">
            Save $150+ <br />Engine Diagnostic Scan
          </p>

          <p>
            Mechanics charge $150 just to scan your car. With FIXD, you can
            diagnose your check engine light in seconds, right from your phone.
          </p>
        </div>
        <div
          class="text-center mx-6 my-1 md:m-2.5 leading-tight flex flex-col items-center"
        >
          <div
            class="rounded-full size-40 bg-white flex items-center justify-center mb-6"
          >
            <Phone color="green" class="size-28" />
          </div>
          <p class="font-extrabold mb-6">
            Save $40+ <br />Live Mechanic Hotline
          </p>

          <p>
            Get expert advice or a trusted second opinion from our Mechanic
            Hotline, available 6 days a week to answer your car questions!
          </p>
        </div>
        <div
          class="text-center mx-6 my-1 md:m-2.5 leading-tight flex flex-col items-center"
        >
          <div
            class="rounded-full size-40 bg-white flex items-center justify-center mb-6"
          >
            <Clipboard color="green" size="4xl" class="size-20" />
          </div>
          <p class="font-extrabold mb-6">Save $40+ <br />Used Car Report</p>

          <p>
            Shopping for a used vehicle? Get previous owner info, accident
            history and more with FIXD. Similar reports cost $40 each elsewhere.
          </p>
        </div>
      </div>
      <div class="flex justify-center w-full mt-20">
        <slot name="cta" />
      </div>
    </div>
  </div>
</Section>
