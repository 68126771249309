<script>
  import { onMount, setContext } from 'svelte'

  import PaypalButton from '@/components/PaypalButton.svelte'
  import GooglePayButton from '@/components/GooglePayButton.svelte'
  import ApplePayButton from '@/components/ApplePayButton.svelte'
  import Experiment from '@/components/Experiment.svelte'

  import api from '@/api'
  import Money from '@/util/money.mjs'
  import { clickOrEnterHandler } from '@/util/svelte.js'
  import { encodeURIParams } from '@/util/methods.js'
  import ExpressButtons from '@/stores/expressButtons.js'

  import BuyBoxImage from '@/images/landing-page/scorpio/L017/buybox1.png'
  import BuyBoxImage2 from '@/images/landing-page/scorpio/L017/buybox2.png'
  import BuyBoxImage3 from '@/images/landing-page/scorpio/L017/buybox3.png'
  import BuyBoxImage4 from '@/images/landing-page/scorpio/L017/buybox4.png'

  import Tracker from '@/util/tracker.js'

  import Skeleton from '@/components/designs/Skeleton.svelte'

  import ThumbnailImages from '@/pages/scorpio/components/L017/ThumbnailImages.svelte'

  const images2 = [
    { url: BuyBoxImage, altText: 'Single Sensor' },
    { url: BuyBoxImage2, altText: 'Plug In' },
    { url: BuyBoxImage3, altText: 'Maintenance Alerts' },
    { url: BuyBoxImage4, altText: 'Multiple Vehicles' },
  ]

  export let funnel
  export let gateway
  export let landingPageCheckoutURL

  let selectedQuantity = getQuantity(funnel.default_initial_offer_slug)
  let selectedPack = getPack(funnel.default_initial_offer_slug)

  let buyboxElement

  $: selectedOffer =
    updateSelection(selectedQuantity, selectedPack) ??
    funnel.initial_offers.find(
      (o) => o.slug === funnel.default_initial_offer_slug,
    )
  $: selectedOfferSlug =
    selectedOffer?.slug ?? funnel.default_initial_offer_slug

  $: allowedPacks = new Set(funnel.initial_offers.map((o) => getPack(o.slug)))

  $: selectedOfferStrikethroughPrice = selectedOffer.line_items.find(
    (offer) => offer.product.sku === '1001',
  ).strikethrough_price

  const expressButtons = new ExpressButtons()
  setContext('express_buttons', expressButtons)
  const { state: expressButtonsState } = expressButtons

  $: enabledExpressCheckouts =
    gateway.name === 'braintree'
      ? new Set(gateway.express_checkouts_enabled)
      : new Set()

  $: warrantyPrice =
    allowedPacks.has('w') &&
    new Money(
      funnel.initial_offers
        .find((o) => getPack(o.slug) === 'w')
        .line_items.find((li) => li.product.sku === 'LW01').price,
    )

  let loadingPaypalButton = true
  let loadingGooglePayButton = true
  let loadingApplePayButton = true

  function updateSelection(selectedQuantity, selectedPack) {
    return funnel.initial_offers.find((o) => {
      return (
        getQuantity(o.slug) === selectedQuantity &&
        getPack(o.slug) === selectedPack
      )
    })
  }

  // TODO: this could be a bit cleaner, it relies on slug naming conventions
  function getQuantity(offerSlug) {
    return offerSlug?.slice(0, 3)
  }

  function getPack(offerSlug) {
    return offerSlug?.substr(-1) === 'w' ? 'w' : ''
  }

  function generateCheckoutURL(selectedOfferSlug) {
    return api.checkouts.new.path({
      funnel_slug: funnel.slug,
      offer_slug: selectedOfferSlug,
    })
  }

  $: checkoutURL = generateCheckoutURL(selectedOfferSlug)

  $: landingPageCheckoutURL = checkoutURL

  onMount(() => {
    document.addEventListener('upsellery_analytics_updated', () => {
      checkoutURL = generateCheckoutURL(selectedOfferSlug)
    })
  })

  async function redirectToCheckoutUrl() {
    await Tracker.track('LP order now clicked')
    window.location.href = checkoutURL
  }

  let device_data = null

  function onPayPalLoad(ev) {
    ;({ device_data } = ev.detail)

    Tracker.track('LP Paypal', {
      variant: 'loaded',
    })
  }

  const onPayPalClick = () => {
    Tracker.track('LP Paypal', {
      variant: 'clicked',
    })
  }

  function onPaypalSubmit(ev) {
    const { payload } = ev.detail

    const params = {
      device_data: JSON.stringify(device_data),
      paypal: JSON.stringify(payload),
    }
    Tracker.track('LP Paypal', {
      variant: 'submitted',
    })
    window.location.href = `${checkoutURL}?${encodeURIParams(params)}`
  }

  function onGooglePayLoad(ev) {
    loadingGooglePayButton = false

    device_data ||= ev.detail.deviceData
    Tracker.track('LP Google Pay', {
      variant: 'loaded',
    })
  }

  async function onGooglePayClick(ev) {
    const { paymentsClient, googlePaymentInstance } = ev.detail

    try {
      const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest(
        {
          transactionInfo: {
            currencyCode: 'USD',
            totalPriceStatus: 'ESTIMATED',
            totalPrice: selectedOffer.subtotal_price.amount.toString(),
          },
          emailRequired: true,
          shippingAddressRequired: true,
        },
      )

      const paymentData =
        await paymentsClient.loadPaymentData(paymentDataRequest)
      const result = await googlePaymentInstance.parseResponse(paymentData)

      const params = {
        device_data: JSON.stringify(device_data),
        google_pay: JSON.stringify({ paymentData, result }),
      }
      window.location.href = `${checkoutURL}?${encodeURIParams(params)}`
    } catch (err) {
      if (err.statusCode === 'CANCELED') {
        console.log('Google Pay payment canceled')
        return
      }
      throw err
    }
  }

  function onApplePayLoad(ev) {
    loadingApplePayButton = false

    device_data ||= ev.detail.deviceData
    Tracker.track('LP Apple Pay', {
      variant: 'load',
    })
  }

  async function onApplePayClick(ev) {
    const { applePay } = ev.detail

    const req = applePay.createPaymentRequest({
      total: {
        label: 'FIXD Automotive',
        amount: selectedOffer.subtotal_price.amount.toString(),
      },
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['postalAddress', 'name', 'email'],
    })

    const session = new window.ApplePaySession(3, req)
    session.onvalidatemerchant = async (ev) => {
      try {
        const msession = await applePay.performValidation({
          validationURL: ev.validationURL,
          displayName: 'Upsellery',
        })
        await session.completeMerchantValidation(msession)
      } catch (err) {
        console.error('onvalidatemerchant', err)
        session.abort()
      }
    }
    session.onpaymentauthorized = async (ev) => {
      try {
        const tokenized = await applePay.tokenize({
          token: ev.payment.token,
        })
        const params = {
          device_data: JSON.stringify(device_data),
          apple_pay: JSON.stringify({ payment: ev.payment, tokenized }),
        }
        window.location.href = `${checkoutURL}?${encodeURIParams(params)}`

        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
      } catch (err) {
        console.error('onpaymentauthorized', err)
        try {
          session.completePayment(window.ApplePaySession.STATUS_FAILURE)
        } catch (err2) {
          console.error(err2)
        }
      }
    }

    session.begin()
  }

  const changePackCount = (quantity) => {
    selectedQuantity = quantity
  }
</script>

<style>
  .quantity-button {
    background-color: #f0f0f0;
    width: 24px;
    height: 100%;
  }

  .custom-button {
    padding-left: 0;
    padding-right: 0;
  }
</style>

<div
  class="px-2.5 rounded-2xl text-left font-inter py-5 md:font-montserrat buy-box"
  bind:this={buyboxElement}
>
  <div class="md:flex items-center">
    <div class="items-center basis-1/2">
      <div class="w-full h-inherit mb-4 md:mb-0 md:mr-0">
        <div class="font-inter">
          <ThumbnailImages images={images2} fullImage={true} />
        </div>
      </div>
    </div>

    <div class="basis-1/2 font-montserrat">
      <div class="description">
        <div class="xl:w-4/5">
          <div class="flex items-center">
            <p class="font-extrabold">
              <span class="strikethrough"
                >${selectedOfferStrikethroughPrice.amount}</span
              >
              <span class="text-green"
                >{new Money(selectedOffer.subtotal_price).toString()}</span
              >
            </p>
          </div>
          <div>
            <h2 class="font-extrabold">FIXD OBD2 SENSOR</h2>

            <div class="mt-4">
              <p class="text-sm font-bold uppercase">product details</p>
              <ul class="font-medium leading-tight pl-1">
                <li>&bull; CONNECTS YOUR SMARTPHONE TO YOUR CAR</li>
                <li>&bull; 1.5 X 0.5 X 1.75 INCHES</li>
                <li>
                  &bull; WORKS ON GAS POWERED CARS 1996 & NEWER <span
                    class="text-green font-bold">CHECK COMPATIBILITY</span
                  >
                </li>
              </ul>
            </div>
            <div class="pl-4 pt-4">
              <p>Diagnose Check Engine Lights</p>
              <p>Save $1000s on Car Care</p>
              <p>Feel Confident at the Repair Shop</p>
              <p>Keep Your Family Safe on the Road</p>
              <p>Prevent Breakdowns and Repairs</p>
            </div>
          </div>

          <div class="pl-2 space-y-1 py-2">
            <div class="flex items-center gap-2">
              <div class="flex items-center">
                <div
                  class="flex items-center font-bold border border-grey-200 rounded h-8"
                >
                  <button
                    class="bare quantity-button"
                    disabled={selectedQuantity === '1pk'}
                    class:text-grey={selectedQuantity === '1pk'}
                    on:click={() => {
                      switch (selectedQuantity) {
                        case '3pk':
                          changePackCount('2pk')
                          break
                        case '2pk':
                          changePackCount('1pk')
                          break
                        default:
                          changePackCount('1pk')
                          break
                      }
                    }}>-</button
                  >
                  <div class="px-4 border-grey-200 h-full border-x pt-[1px]">
                    <p class="text-lg">{selectedQuantity[0]}</p>
                  </div>
                  <button
                    disabled={selectedQuantity === '3pk'}
                    class:text-grey={selectedQuantity === '3pk'}
                    class="bare quantity-button"
                    on:click={() => {
                      switch (selectedQuantity) {
                        case '1pk':
                          changePackCount('2pk')
                          break
                        case '2pk':
                          changePackCount('3pk')
                          break
                        default:
                          changePackCount('2pk')
                          break
                      }
                    }}>+</button
                  >
                </div>
              </div>

              {#if allowedPacks.has('w')}
                <div>
                  <div class="flex items-center flex-row gap-2">
                    <div
                      class="border border-2 flex items-center justify-center rounded-full size-6"
                      class:border-green={selectedPack === 'w'}
                    >
                      <input
                        type="checkbox"
                        class="bg-grey-300 border-0 rounded-full outline-none focus:border-0"
                        style="width: 1rem; height: 1rem; border-width: 0; box-shadow: none;"
                        use:clickOrEnterHandler={(event) => {
                          event.target.checked
                            ? (selectedPack = 'w')
                            : (selectedPack = '')
                        }}
                      />
                    </div>

                    <span class="text-sm font-bold"
                      >Lifetime Warranty - <span class="font-bold"
                        >{warrantyPrice.toString()}</span
                      ></span
                    >
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </div>
      </div>

      <div class="w-full md:w-3/5 mt-4">
        <div class="mt-3 md:px-0 md:mt-0 order-button">
          <div class="gap-3 md:block items-center w-full space-y-2 md:px-0">
            <div class="flex-1">
              <div class="w-full">
                <button
                  class="rounded-button custom-button"
                  on:click={() => redirectToCheckoutUrl()}
                >
                  Add to Cart
                </button>
              </div>
            </div>

            <div class="flex flex-col sm:flex-row gap-1">
              {#if enabledExpressCheckouts.has('paypal')}
                <div class="relative min-h-[50px] flex-1">
                  <div
                    class="absolute z-20 top-0 left-0 h-[50px] w-full"
                    class:hidden={!loadingPaypalButton}
                  >
                    <Skeleton height="50" shape="pill" />
                  </div>

                  <PaypalButton
                    shape="pill"
                    bind:loading={loadingPaypalButton}
                    height={50}
                    extraClasses="min-h-[50px]"
                    tokenizationKey={gateway.tokenization_key}
                    on:click={onPayPalClick}
                    on:load={onPayPalLoad}
                    on:submit={onPaypalSubmit}
                    on:mockCheckout={() => console.log('Mock PayPal checkout.')}
                  />
                </div>
              {/if}

              <Experiment name="C0-2024-08-30-lp-express-checkouts" let:value>
                {#if value === 'On' && enabledExpressCheckouts.has('google_pay') && $expressButtonsState.google_pay !== false}
                  <div class="relative min-h-[50px] flex-1">
                    <div
                      class="absolute z-20 top-0 left-0 h-[50px] w-full"
                      class:hidden={!loadingGooglePayButton}
                    >
                      <Skeleton height="50" shape="pill" />
                    </div>

                    <GooglePayButton
                      tokenizationKey={gateway.tokenization_key}
                      radius={100}
                      on:load={onGooglePayLoad}
                      on:click={onGooglePayClick}
                    />
                  </div>
                {/if}

                {#if value === 'On' && enabledExpressCheckouts.has('apple_pay') && $expressButtonsState.apple_pay !== false}
                  <div class="relative min-h-[50px] flex-1">
                    <div
                      class="absolute z-20 top-0 left-0 h-[50px] w-full"
                      class:hidden={!loadingApplePayButton}
                    >
                      <Skeleton height="50" shape="pill" />
                    </div>

                    <ApplePayButton
                      tokenizationKey={gateway.tokenization_key}
                      pill={true}
                      on:load={onApplePayLoad}
                      on:click={onApplePayClick}
                    />
                  </div>
                {/if}
              </Experiment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
