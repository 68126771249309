<!-- This button will smooth scroll the user to the BuyBox, which means
it assumes the BuyBox is somewhere on the page. -->
<script>
  export let scrollToBuyBox = true
  export let checkoutURL

  let loading = false
  function jumpToBuyBox() {
    document.querySelector('.buy-box').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
  const linkToCheckout = () => {
    loading = true
    window.location.href = checkoutURL
  }
</script>

<button
  on:click={() => (scrollToBuyBox ? jumpToBuyBox() : linkToCheckout())}
  class="bare {$$props.class}"
  class:disabled={loading}
  disabled={loading}
>
  <slot />
</button>
