<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import {
    Logo,
    FixdLogoText,
    CloseRound,
    CheckmarkFilled,
  } from '@/components/icons'
</script>

<Section
  center="true"
  maxWidth="2xl"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 bg-grey-200 md:px-[120px]">
    <div class="mb-6">
      <h1 class="font-extrabold">
        FIXD Vs. Your Mechanic's Professional Scan Tool:
      </h1>
    </div>

    <div
      class="md:mx-22 bg-white rounded-3xl md:pl-[84px] pt-10 px-3 md:pr-[100px] pb-8 md:pt-16"
      style="box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%)"
    >
      <div class="flex items-center mb-8 md:mb-4">
        <div class="basis-7/12 h-2"></div>
        <div class="basis-3/12 sm:basis-2/12 flex items-center justify-center">
          <div
            class="rotate-[315deg] -mr-2.5 md:mr-0 md:rotate-0 inline-flex items-center gap-1"
          >
            <Logo color={'green'} size="sm" class="size-5 md:size-7" />
            <FixdLogoText
              color={'#3F4147'}
              size="sm"
              class="w-12 h-4 md:w-16 md:h-5"
            />
          </div>
        </div>
        <div class="basis-3/12 flex items-center justify-center">
          <p
            class="w-[86px] -mr-2.5 md:mr-0 md:w-full rotate-[315deg] md:rotate-0 font-bold md:font-extrabold text-xl md:text-2xl"
          >
            Pro Tool
          </p>
        </div>
      </div>

      <div class="space-y-4">
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Diagnose & clear 7,000+ check engine lights
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Translate car problems into plain English on your smartphone
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Find trustworthy repair shops nearby
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Know if it's safe to keep driving
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              See how much repairs should cost so you don't get ripped off*
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Get expert advice from our Mechanic Hotline*
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Avoid a lemon with Used Vehicle History Report*
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Predict your most likely future car problems with Issue Forecast*
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Predict your most likely future car problems with Issue Forecast*
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              24/7 AI Mechanic for all your car questions*
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <div
              class="rounded-full size-9 bg-green flex items-center justify-center"
            >
              <CheckmarkFilled size="xl" color="green" />
            </div>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <div
              class="rounded-full size-9 bg-black flex items-center justify-center"
            >
              <CloseRound color="white" size="sm" />
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <div
            class="basis-6/12 md:basis-7/12 sm:max-w-[402px] mr-auto pr-3 md:pr-0 min-h-[50px] flex items-center"
          >
            <p class="md:text-xl font-semibold text-left leading-tight">
              Cost:
            </p>
          </div>
          <div
            class="basis-3/12 sm:basis-2/12 flex items-center justify-center"
          >
            <p
              class="text-xl md:text-2xl font-semibold text-left leading-tight text-green"
            >
              $19.99
            </p>
          </div>
          <div class="basis-3/12 flex items-center justify-center">
            <p
              class="text-xl md:text-2xl font-semibold text-left leading-tight text-red"
            >
              $2,000+
            </p>
          </div>
        </div>
      </div>
      <div class="font-semibold text-grey text-left pr-20">
        *Free for 14 Days with FIXD Premium Trial
      </div>
    </div>
  </div>
</Section>
