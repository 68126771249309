<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { FaStarFilledRound } from '@/components/icons'
</script>

<Section maxWidth="lg">
  <div class="text-center">
    <div class="mb-8">
      {#each new Array(5) as _}
        <FaStarFilledRound color="yellow" size="xl" />
      {/each}
    </div>

    <div class="flex justify-center md:gap-6">
      <div>
        <p class="font-extrabold text-2xl md:text-4xl mb-6">
          “Paid for itself the first time I used it.”
        </p>
        <p class="text-xl leading-tight">
          Mark Michael, <span class="italic"> Verified Buyer</span>
        </p>
      </div>
    </div>
  </div>
</Section>
