<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import MoneyBack from '@/images/landing-page/scorpio/io/money-back.png'
</script>

<Section center="true" maxWidth="lg">
  <div class="flex flex-col-reverse md:flex-col md:mb-6">
    <div class="md:mb-6">
      <p class="text-xl font-extrabold mb-4">
        Free 14-Day Premium Trial | Free Shipping | Free 1-Year Warranty
      </p>

      <h1 class="font-extrabold mb-6">100% RISK-FREE Money Back Guarantee</h1>
    </div>

    <img
      src={MoneyBack}
      loading="lazy"
      alt="money back guarantee"
      class="mb-9 md:mb-0 w-full md:w-3/5 mx-auto"
    />
  </div>

  <div>
    <p class="font-medium text-xl">
      Go ahead and try FIXD today for just $19.99. Test drive all the features,
      including everything FIXD Premium has to offer, for the next 14 days.
      Enjoy $230+ in instant value from our diagnostic scans, Mechanic Hotline,
      Used Vehicle Reports, and more. If you’re not satisfied for any reason,
      simply send it back for a full, prompt, and courteous refund. We’ll even
      pay for your return shipping!
    </p>
  </div>
</Section>
