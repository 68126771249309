<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Image1 from '@/images/landing-page/scorpio/bfcm/3-1.png'
  import Image2 from '@/images/landing-page/scorpio/bfcm/3-2.png'
  import Image3 from '@/images/landing-page/scorpio/bfcm/3-3.png'
</script>

<Section
  center="true"
  background="none"
  horizontalPadding="none"
  verticalPadding="none"
  maxWidth="xl"
>
  <div class="bg-[#f2f2f2] py-11 px-3">
    <div class="mb-10">
      <h2>3 Reasons FIXD Is the Ultimate 2024 Holiday Gift:</h2>
    </div>

    <div class="flex flex-col md:flex-row mb-7 gap-5 md:gap-3">
      <div class="text-center basis-1/3">
        <img
          src={Image1}
          alt="effortless for anyone"
          loading="lazy"
          class="mb-3"
        />

        <div class="leading-tight">
          <p class="font-bold text-xl mb-4">Effortless for Anyone</p>
          <p class="font-medium text-xl">
            No car knowledge? No problem! FIXD is easy to setup and use
            instantly!
          </p>
        </div>
      </div>

      <div class="text-center basis-1/3">
        <img src={Image2} alt="Big Savings" loading="lazy" class="mb-3" />

        <div class="leading-tight">
          <p class="font-bold text-xl mb-4">Big Savings</p>
          <p class="font-medium text-xl">
            FIXD helps your loved ones save $1000s on repairs.
          </p>
        </div>
      </div>
      <div class="text-center basis-1/3">
        <img src={Image3} alt="Holiday Steal" loading="lazy" class="mb-3" />

        <div class="leading-tight">
          <p class="font-bold text-xl mb-4">Holiday Steal</p>
          <p class="font-medium text-xl">
            Give the gift of peace of mind this season! Now 67% off- just
            $19.99!
          </p>
        </div>
      </div>
    </div>
    <div class="trf-container">
      <slot name="cta" />
    </div>
  </div>
</Section>
