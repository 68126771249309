<script>
  import { CheckmarkFilled } from '@/components/icons'

  export let label
  export let free = false
  export let premium = true
  export let whiteCheckMarkBackground = false

  export let last = false
</script>

{#if $$slots.label}
  <slot name="label" />
{:else}
  <div
    class="col-span-4 md:col-span-8 flex flex-row justify-start items-center gap-1"
  >
    {label}
  </div>
{/if}

{#if $$slots.free}
  <slot name="free" />
{:else if free === true}
  <div class="flex justify-center items-center p-4">
    <CheckmarkFilled
      color={whiteCheckMarkBackground ? 'white' : '#e9eff1'}
      checkmarkColor="black"
      class=""
    />
  </div>
{:else if free !== false}
  {free}
{:else}
  <div />
{/if}

{#if $$slots.premium}
  <slot name="premium" />
{:else if premium === true}
  <div
    class="bg-green-300 p-4 flex justify-center items-center"
    class:rounded-b-lg={last}
  >
    <CheckmarkFilled color="green" />
  </div>
{:else if premium !== false}
  {premium}
{:else}
  <div />
{/if}
