<script>
  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { Logo, FixdLogoText } from '@/components/icons'

  import HeroImage from '@/images/landing-page/scorpio/bfcm/banner.png'
  import HeroMobileImage from '@/images/landing-page/scorpio/bfcm/bfcm-banner-mobile.png'
  import ThreeReasons from '@/pages/scorpio/bfcm/components/ThreeReasons.svelte'
  import TryRiskFree from '@/pages/scorpio/bfcm/components/TryRiskFree.svelte'

  import JumpToBuyBoxButton from '@/pages/scorpio/bfcm/components/JumpToBuyBoxButton.svelte'
  import BuyBoxL007 from '@/pages/scorpio/components/L007/BuyBoxL007.svelte'
  import Benefits from '@/components/landing-page/Benefits.svelte'
  import HowItWorks from '@/pages/scorpio/components/io/06HowItWorks.svelte'
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import AsSeenOn from '@/pages/scorpio/components/io/02AsSeenOn.svelte'
  import FAQ from '@/components/landing-page/FAQ.svelte'
  import About from '@/pages/scorpio/components/io/12About.svelte'

  export let funnel
  export let gateway
</script>

<main class="text-black relative">
  <Section maxWidth="xl" horizontalPadding="none" verticalPadding="none">
    <div class="relative py-11 md:py-0 bg-[#1a1a1a]">
      <div class="absolute w-full top-0">
        <div
          class="flex items-center m-auto
             container max-w-[940px] px-5 justify-center"
        >
          <div class="flex items-center gap-2 mt-5">
            <Logo color="white" size="sm" />
            <FixdLogoText color="white" size="sm" />
          </div>
        </div>
      </div>
      <img src={HeroImage} alt="bfcm-fixd" class="w-full hidden sm:block" />
      <img src={HeroMobileImage} alt="bfcm-fixd" class="w-full sm:hidden" />

      <div class="sm:hidden w-10/12 mx-auto px-6">
        <JumpToBuyBoxButton
          slot="cta"
          class="py-5 text-white bg-green rounded-full w-full"
        >
          <span class="text-xl uppercase font-bold">buy now</span>
        </JumpToBuyBoxButton>
      </div>

      <div class="hidden sm:block absolute bottom-0 w-5/12 ml-[3%] mb-[6%]">
        <JumpToBuyBoxButton
          slot="cta"
          class="py-5 text-white bg-green rounded-full w-7/12"
        >
          <span class="uppercase text-xl font-bold">buy now</span>
        </JumpToBuyBoxButton>
      </div>
    </div>
  </Section>

  <ThreeReasons />

  <Section center="true" maxWidth="2xl" horizontalPadding="none">
    <BuyBoxL007 {funnel} {gateway} />
  </Section>

  <Benefits />

  <div class="max-w-screen-xl mx-auto">
    <HowItWorks>
      <div class="flex justify-center w-full" slot="cta">
        <JumpToBuyBoxButton
          slot="cta"
          class="py-5 text-white bg-green rounded-full w-8/12 md:w-4/12"
        >
          <span class="uppercase md:hidden text-xl font-bold"
            >GET $230+ IN SAVINGS
          </span>
          <span class="hidden md:block uppercase text-xl font-bold"
            >GET FIXD 67% OFF</span
          >
        </JumpToBuyBoxButton>
      </div>
    </HowItWorks>
  </div>

  <TryRiskFree />

  <AsSeenOn>
    <div class="mb-8" slot="header">
      <p class="text-center font-bold uppercase mb">as seen on:</p>
    </div>
  </AsSeenOn>

  <LiveReviews />

  <FAQ />

  <About />

  <Footer />
</main>
