<script>
  import { Splide, SplideTrack } from '@splidejs/svelte-splide'
  import CarouselItem from '@/components/CarouselItem.svelte'

  export let images
  export let fullImage = false

  $: imageIndex = 0

  let splideRef

  const changeImageIndex = (index) => {
    imageIndex = index
    splideRef.go(index + 1)
  }
</script>

<div class="flex items-center">
  <div class="grow h-full w-full">
    <Splide
      bind:this={splideRef}
      hasTrack={false}
      options={{
        arrows: false,
        pagination: false,
      }}
      on:moved={(e) => (imageIndex = e.detail.index - 1)}
    >
      <SplideTrack>
        <CarouselItem>
          <div class="flex items-center justify-center h-full">
            <img
              src={images[0].url}
              alt={images[0].altText}
              class={fullImage ? 'w-full md:w-4/5' : 'w-3/5'}
            />
          </div>
        </CarouselItem>
        {#each images.slice(1, 6) as item}
          <CarouselItem>
            <div class="flex items-center justify-center h-full">
              <img src={item.url} alt={item.altText} />
            </div>
          </CarouselItem>
        {/each}
      </SplideTrack>
    </Splide>
    <div class="flex shrink-0 space-x-2.5 my-2 justify-center">
      {#each images.slice(1, 4) as item, i}
        <div
          class="hover:cursor-pointer border-2 rounded"
          class:border-green={imageIndex === i}
          role="button"
          on:click={() => changeImageIndex(i)}
          on:keypress={() => changeImageIndex(i)}
          tabindex="-1"
        >
          <img
            src={item.url}
            alt={item.altText}
            class="size-20 2xl:size-[100px]"
          />
        </div>
      {/each}
    </div>
  </div>
</div>
