<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Image1 from '@/images/landing-page/scorpio/bfcm/free-shipping.png'
  import Image2 from '@/images/landing-page/scorpio/bfcm/1-year-warranty.png'
  import Image3 from '@/images/landing-page/scorpio/bfcm/money-back-guarantee.png'
</script>

<Section center="true" background="none" maxWidth="xl" verticalPadding="none">
  <div class="pt-10 md:pt-16">
    <div class="mb-10">
      <h2>TRY RISK FREE AND SAVE 67%</h2>
    </div>

    <div class="flex flex-col md:flex-row mb-7 gap-5 md:gap-3">
      <div class="text-center basis-1/3">
        <img
          src={Image1}
          alt="effortless for anyone"
          loading="lazy"
          class="mb-3"
        />

        <p class="font-semibold">
          Free Domestic Shipping <br />on all U.S. Orders
        </p>
      </div>

      <div class="text-center basis-1/3">
        <img src={Image2} alt="Big Savings" loading="lazy" class="mb-3" />

        <p class="font-semibold">
          1 Year Limited Warranty on <br />FIXD Sensors
        </p>
      </div>
      <div class="text-center basis-1/3">
        <img src={Image3} alt="Holiday Steal" loading="lazy" class="mb-3" />

        <p class="font-semibold">
          30-Day Risk-Free Money Back <br />Guarantee
        </p>
      </div>
    </div>
    <div class="trf-container">
      <slot name="cta" />
    </div>
  </div>
</Section>
