<script>
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import Footer from '@/components/landing-page/Footer.svelte'
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Hero from '@/pages/scorpio/components/L017/Hero.svelte'
  import Banner from '@/pages/scorpio/components/L017/Banner.svelte'
  import ThreeReasons from '@/pages/scorpio/components/L017/ThreeReasons.svelte'
  import PeaceOfMind from '@/pages/scorpio/components/L017/PeaceOfMind.svelte'
  import BuyBox from '@/pages/scorpio/components/L017/BuyBox.svelte'
  import Compatibility from '@/pages/scorpio/components/L017/Compatibility.svelte'
  import Sponsors from '@/pages/scorpio/components/L017/Sponsors.svelte'
  import Values from '@/pages/scorpio/components/L017/Values.svelte'
  import FAQ from '@/pages/scorpio/components/L017/FAQ.svelte'

  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  export let funnel
  export let gateway
  export let darkTheme = false
</script>

<main
  class="md:pb-0 {darkTheme ? 'bg-black-200' : ''}"
  class:text-white={darkTheme}
>
  <Banner {darkTheme} />

  <Hero {darkTheme}>
    <div class="text-center mx-auto mt-10" slot="cta">
      <JumpToBuyBoxButton>
        <span class="uppercase text-2xl">get FIXD 67% off</span>
      </JumpToBuyBoxButton>
    </div>
  </Hero>

  <ThreeReasons>
    <div class="md:w-11/12" slot="cta">
      <JumpToBuyBoxButton size="full">
        <span class="uppercase">save on car care with fixd</span>
      </JumpToBuyBoxButton>
    </div>
  </ThreeReasons>

  <PeaceOfMind {darkTheme} />
  <Section maxWidth="xl">
    <BuyBox {funnel} {gateway} />
  </Section>

  <Compatibility {darkTheme}>
    <div slot="content" class="my-10 md:mb-16">
      <p class="font-medium">
        FIXD works with all gas-powered cars and trucks built after 1996 and
        diesels built after 2008.
      </p>
    </div>
    <JumpToBuyBoxButton slot="compatible" maxWidth={56}>
      <span class="uppercase">BUY FIXD NOW</span>
    </JumpToBuyBoxButton>
  </Compatibility>

  <Section center="true" maxWidth="md" horizontalPadding="none">
    <h2 class="mb-8">How does FIXD work?</h2>

    <div class="relative pb-[56.25%]">
      <iframe
        src="https://www.youtube.com/embed/C2I-HDuSrKU"
        title="YouTube video player"
        frameborder="0"
        class="absolute top-0 left-0 w-full h-full"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        allowfullscreen
      />
    </div>
  </Section>

  <Sponsors invert={darkTheme} />

  <LiveReviews {darkTheme} backgroundColor="transparent" />

  <Values {darkTheme} />

  <FAQ>
    <div class="text-center mx-auto mt-10" slot="cta">
      <JumpToBuyBoxButton>
        <span class="uppercase text-2xl">get FIXD 67% off</span>
      </JumpToBuyBoxButton>
    </div>
  </FAQ>

  <Footer />
</main>
