<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { FaStarFilledRound } from '@/components/icons'
</script>

<Section maxWidth="lg">
  <div class="text-center">
    <div class="mb-8">
      {#each new Array(5) as _}
        <FaStarFilledRound color="yellow" size="xl" />
      {/each}
    </div>

    <div class="flex justify-center md:gap-6">
      <div class="self-start">
        <p class="font-bold text-[128px] hidden md:block text-[#647581] -mt-14">
          “
        </p>
      </div>
      <div>
        <p class="font-extrabold text-2xl md:text-4xl mb-6">
          “FIXD IS A MONEY-SAVER, HANDS DOWN.”
        </p>
        <p class="text-xl leading-tight">
          The repair shop told me it would cost $4,500 to get my car running
          right again. With FIXD I did it myself - easy as pie - and my car is
          running like new. Thank you!
        </p>
      </div>
      <p
        class="font-bold text-[128px] rotate-180 hidden md:block text-[#E0E1E7] -mt-14"
      >
        “
      </p>
    </div>
  </div>
</Section>
