<script>
  import HeroImage from '@/images/landing-page/scorpio/io/hero.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { Logo, FixdLogoText, FaStarFilledRound } from '@/components/icons'
</script>

<style>
  .container {
    background: rgb(0 0 0 / 30%);
  }

  .title {
    text-shadow: 4px 4px 8.4px rgb(0 0 0 / 67%);
  }

  @media only screen and (width >= 768px) {
    .container {
      background: linear-gradient(
        to bottom,
        rgb(0 0 0 / 80%),
        rgb(102 102 102 / 0%)
      );
    }
  }
</style>

<Section horizontalPadding="none" verticalPadding="none" maxWidth="2xl">
  <div
    class="hero-container h-[374px] md:h-[584px] w-full bg-no-repeat md:bg-center bg-[60%] bg-cover"
    style="background-image: url({HeroImage});
"
  >
    <div class="flex items-center gap-2 justify-center mb-10 pt-4">
      <Logo color="white" size="sm" />
      <FixdLogoText color="white" size="sm" />
    </div>

    <div
      class="w-full md:w-5/12 container text-white py-6 md:p-5 md:rounded-3xl leading-tight md:ml-[10%]"
    >
      <h1
        class="text-40 text-center md:text-left font-semibold px-4 md:px-0 title"
      >
        Get $230+ in car savings for only $19.99
      </h1>

      <div class="hidden md:block">
        <p class="py-4">
          Try FIXD risk-free today and get a free trial of FIXD Premium. You'll
          get instant access to our most advanced engine diagnostics, live
          Mechanic Hotline, cost estimates, and more for the next 14 days - all
          for less than a fast food dinner for two!
        </p>

        <div class="flex items-center">
          {#each new Array(5) as _}
            <FaStarFilledRound color="white" size="sm" />
          {/each}
          <p class="pl-1">50,000+ Reviews</p>
        </div>
        <div class="mt-7">
          <slot name="cta" />
        </div>
      </div>
    </div>
  </div>
</Section>

<div class="block md:hidden font-semibold">
  <Section verticalPadding="none">
    <p class="py-4 font-semibold">
      Try FIXD risk-free today and get a free trial of FIXD Premium. You'll get
      instant access to our most advanced engine diagnostics, live Mechanic
      Hotline, cost estimates, and more for the next 14 days - all for less than
      a fast food dinner for two!
    </p>

    <div class="flex items-center">
      {#each new Array(5) as _}
        <FaStarFilledRound color="black" size="sm" />
      {/each}
      <p class="pl-1">50,000+ Reviews</p>
    </div>

    <div class="mt-7">
      <slot name="cta" />
    </div>
  </Section>
</div>
