<script>
  import Row from '@/components/landing-page/PremiumMessagingRow.svelte'
  import { Chevron, Guard } from '@/components/icons'

  let expanded = false
  $: direction = expanded ? 'up' : 'down'

  function toggle() {
    expanded = !expanded
  }
</script>

<div
  class="w-full md:px-8 px-2 py-4 md:py-10 md:gap-8 flex flex-col md:flex-row font-inter"
>
  <div
    class="flex-col justify-center items-start gap-4 leading-tight basis-1/2 hidden md:flex"
  >
    <div class="md:text-4xl font-medium">
      Each Sensor Purchase Comes with an Optional Free Premium Upgrade
    </div>
    <div class="md:text-xl">
      Gain full access to what the FIXD App has to offer with these FIXD Premium
      benefits
    </div>
  </div>
  <div class="md:hidden">
    <p class="font-medium text-xl leading-tight">
      Each Sensor Purchase Comes with an Optional Free Premium Upgrade
    </p>
    <button class="bare" on:click={toggle} on:keypress={toggle}>
      <div class="flex items-center gap-1 text-lg mt-2 font-medium">
        <p>View</p>
        <Guard color="green" />
        <p><span class="text-green">FIXD Premium</span> Benefits</p>

        <div
          class="justify-center items-center size-6 flex bg-slate-200 rounded ml-2"
        >
          <Chevron color="black" {direction} size="xs" />
        </div>
      </div>
    </button>
  </div>

  <div
    class="md:bg-white p-4 rounded-xl basis-1/2 self-center grid-cols-6 md:grid-cols-10 shadow hidden md:grid"
  >
    <div
      class="font-bold cursor-pointer contents"
      on:click|preventDefault={toggle}
      on:keypress|preventDefault={toggle}
      role="button"
      tabindex="0"
      aria-pressed={expanded}
    >
      <Row>
        <div
          slot="label"
          class="col-span-4 md:col-span-8 flex flex-row justify-start items-center gap-1"
        >
          <div class="hidden md:contents">
            <Guard color="green" size="xl" class="hidden md:block" />

            <span class="text-green text-xl whitespace-nowrap"
              >FIXD Premium</span
            >
            <span class="text-xl">Benefits</span>
          </div>
          <div class="contents md:hidden">Premium benefits</div>
          <div
            class="justify-center items-center size-6 flex bg-slate-200 rounded ml-2"
          >
            <Chevron color="black" {direction} size="xs" />
          </div>
        </div>
        <div slot="free" class="flex justify-center items-center">
          <p class="font-semibold text-xl">Free</p>
        </div>
        <div
          slot="premium"
          class="bg-green py-2.5 px-4 text-white flex justify-center items-center"
          class:rounded-lg={!expanded}
          class:rounded-t-lg={expanded}
        >
          <Guard color="white" size="xl" />
        </div>
      </Row>
    </div>
    {#if expanded}
      <Row label="Check Engine Diagnostics" free />
      <Row label="Maintenance Alerts" free />
      <Row label="Multi-Vehicle Support" free />
      <Row label="Live Data" free />
      <Row label="AI Mechanic" />
      <Row label="Confirmed Fix and Cost" />
      <Row label="FIXD Mechanic Hotline" />
      <Row label="Emissions Precheck" />
      <Row label="Issue Forecast" />
      <Row label="Incident History" />
      <Row label="Issue Commonality" last />
    {/if}
  </div>
  <div class="grid md:hidden grid-cols-6 md:grid-cols-10" class:mt-3={expanded}>
    {#if expanded}
      <Row>
        <div
          slot="label"
          class="col-span-4 md:col-span-8 flex flex-row justify-start items-center gap-1"
        >
          <div class="hidden md:contents">
            <Guard color="green" size="xl" class="hidden md:block" />

            <span class="text-green text-xl whitespace-nowrap"
              >FIXD Premium</span
            >
            <span class="text-xl">Benefits</span>
          </div>
        </div>
        <div slot="free" class="flex justify-center items-center">
          <p class="font-semibold text-xl">Free</p>
        </div>
        <div
          slot="premium"
          class="bg-green py-2.5 px-4 text-white flex justify-center items-center"
          class:rounded-lg={!expanded}
          class:rounded-t-lg={expanded}
        >
          <Guard color="white" size="xl" />
        </div>
      </Row>
      <Row
        label="Check Engine Diagnostics"
        free
        whiteCheckMarkBackground={true}
      />
      <Row label="Maintenance Alerts" free whiteCheckMarkBackground={true} />
      <Row label="Multi-Vehicle Support" free whiteCheckMarkBackground={true} />
      <Row label="Live Data" free whiteCheckMarkBackground={true} />
      <Row label="AI Mechanic" whiteCheckMarkBackground={true} />
      <Row label="Confirmed Fix and Cost" whiteCheckMarkBackground={true} />
      <Row label="FIXD Mechanic Hotline" whiteCheckMarkBackground={true} />
      <Row label="Emissions Precheck" whiteCheckMarkBackground={true} />
      <Row label="Issue Forecast" whiteCheckMarkBackground={true} />
      <Row label="Incident History" whiteCheckMarkBackground={true} />
      <Row label="Issue Commonality" last whiteCheckMarkBackground={true} />
    {/if}
  </div>
</div>
