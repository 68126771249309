<script>
  import { onMount, setContext } from 'svelte'

  import PaypalButton from '@/components/PaypalButton.svelte'
  import GooglePayButton from '@/components/GooglePayButton.svelte'
  import ApplePayButton from '@/components/ApplePayButton.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'
  import Experiment from '@/components/Experiment.svelte'

  import api from '@/api'
  import Money from '@/util/money.mjs'
  import { clickOrEnterHandler } from '@/util/svelte.js'
  import { encodeURIParams } from '@/util/methods.js'
  import ExpressButtons from '@/stores/expressButtons.js'

  import SingleSensorImg from '@/images/landing-page/buybox/Sensor_Single_1.png'
  import DoubleSensorImg from '@/images/landing-page/buybox/Sensor_Double.png'
  import TripleSensorImg from '@/images/landing-page/buybox/Sensor_Triple.png'
  import gallery1Img from '@/images/landing-page/scorpio/L007/1.jpg'
  import gallery2Img from '@/images/landing-page/scorpio/L007/2.jpg'
  import gallery3Img from '@/images/landing-page/scorpio/L007/3.png'
  import gallery4Img from '@/images/landing-page/scorpio/L007/4.png'
  import gallery5Img from '@/images/landing-page/scorpio/L007/5.png'
  import gallery6Img from '@/images/landing-page/buybox/6-gallery.jpg'
  import Tracker from '@/util/tracker.js'
  import {
    CheckmarkFilled,
    StarFilledRound,
    Lightning,
    Timer,
    Fire,
    Hourglass,
  } from '@/components/icons'
  import Skeleton from '@/components/designs/Skeleton.svelte'

  import SingleSensor from '@/images/landing-page/scorpio/single-sensor.png'

  import ThumbnailImages from '@/pages/scorpio/components/L007/ThumbnailImages.svelte'

  import ccIconsUrl from '@/images/landing-page/CreditCardIcons.png?height=105'

  const images = [
    { url: SingleSensor, altText: 'Single Sensor' },
    { url: gallery1Img },
    { url: gallery2Img },
    {
      url: gallery3Img,
      altText: `A Driver's Best Friend.
FIXD helps you save $1,000s in car repair by diagnostic 7,000+ car
problems from your smartphone.

Translates Engine Lights into Simple, Understandable Terms.
Get Maintenance Remainders.
Monitor Multiple Vehicles.
Easy Wireless Syncing.
Compatible with both Android and iOS devices.
`,
    },
    {
      url: gallery4Img,
      altText: `Engine Light Diagnosis
Shows why it's on in plain English and allows you to clear the light.
Severity: FIXD gives you the severity and consequences of each check
engine light so you know how quickly you need to get it taken care of.
Clear the Light: FIXD allows you to clear any check engine light, so if
it's just a loose gas cap or if you fix it yourself you don't need to go
to a repair shop.`,
    },
    {
      url: gallery5Img,
      altText: `Maintenance Reminders:
Get automatic alerts for all maintenance and oil changes.
Reminders: FIXD gives you automatic alerts of scheduled maintenance and oil
changes for  your exact vehicle. Forgetting scheduled maintenance can cause
thousands in costly car repairs.
Cost Estimate: FIXD gives you a cost estimate for each maintenance interval
based on your car and the parts and labor rates in your area.`,
    },
    {
      url: gallery6Img,
      altText: `Multi-Vehicle Control:
Link multiple vehicles together to monitor your family of vehicles.
All your cars: FIXD lets you see as many vehicles as you want, all from
one account.
Anywhere: FIXD lets you view your family's vehicles anywhere, anytime, right from
your smartphone.`,
    },
  ]

  const images2 = [
    { url: SingleSensorImg, altText: 'Single Sensor' },
    { url: gallery1Img },
    { url: gallery2Img },
    {
      url: gallery3Img,
      altText: `A Driver's Best Friend.
FIXD helps you save $1,000s in car repair by diagnostic 7,000+ car
problems from your smartphone.

Translates Engine Lights into Simple, Understandable Terms.
Get Maintenance Remainders.
Monitor Multiple Vehicles.
Easy Wireless Syncing.
Compatible with both Android and iOS devices.
`,
    },
    {
      url: gallery4Img,
      altText: `Engine Light Diagnosis
Shows why it's on in plain English and allows you to clear the light.
Severity: FIXD gives you the severity and consequences of each check
engine light so you know how quickly you need to get it taken care of.
Clear the Light: FIXD allows you to clear any check engine light, so if
it's just a loose gas cap or if you fix it yourself you don't need to go
to a repair shop.`,
    },
    {
      url: gallery5Img,
      altText: `Maintenance Reminders:
Get automatic alerts for all maintenance and oil changes.
Reminders: FIXD gives you automatic alerts of scheduled maintenance and oil
changes for  your exact vehicle. Forgetting scheduled maintenance can cause
thousands in costly car repairs.
Cost Estimate: FIXD gives you a cost estimate for each maintenance interval
based on your car and the parts and labor rates in your area.`,
    },
    {
      url: gallery6Img,
      altText: `Multi-Vehicle Control:
Link multiple vehicles together to monitor your family of vehicles.
All your cars: FIXD lets you see as many vehicles as you want, all from
one account.
Anywhere: FIXD lets you view your family's vehicles anywhere, anytime, right from
your smartphone.`,
    },
  ]

  export let funnel
  export let gateway
  export let landingPageCheckoutURL

  let selectedQuantity = getQuantity(funnel.default_initial_offer_slug)
  let selectedPack = getPack(funnel.default_initial_offer_slug)

  let buyboxElement

  $: selectedOffer =
    updateSelection(selectedQuantity, selectedPack) ??
    funnel.initial_offers.find(
      (o) => o.slug === funnel.default_initial_offer_slug,
    )
  $: selectedOfferSlug =
    selectedOffer?.slug ?? funnel.default_initial_offer_slug

  $: allowedPacks = new Set(funnel.initial_offers.map((o) => getPack(o.slug)))

  $: selectedOfferStrikethroughPrice = selectedOffer.line_items.find(
    (offer) => offer.product.sku === '1001',
  ).strikethrough_price

  const expressButtons = new ExpressButtons()
  setContext('express_buttons', expressButtons)
  const { state: expressButtonsState } = expressButtons

  $: enabledExpressCheckouts =
    gateway.name === 'braintree'
      ? new Set(gateway.express_checkouts_enabled)
      : new Set()

  $: warrantyPrice =
    allowedPacks.has('w') &&
    new Money(
      funnel.initial_offers
        .find((o) => getPack(o.slug) === 'w')
        .line_items.find((li) => li.product.sku === 'LW01').price,
    )

  let loadingPaypalButton = true
  let loadingGooglePayButton = true
  let loadingApplePayButton = true

  function updateSelection(selectedQuantity, selectedPack) {
    return funnel.initial_offers.find((o) => {
      return (
        getQuantity(o.slug) === selectedQuantity &&
        getPack(o.slug) === selectedPack
      )
    })
  }

  // TODO: this could be a bit cleaner, it relies on slug naming conventions
  function getQuantity(offerSlug) {
    return offerSlug?.slice(0, 3)
  }

  function getPack(offerSlug) {
    return offerSlug?.substr(-1) === 'w' ? 'w' : ''
  }

  function generateCheckoutURL(selectedOfferSlug) {
    return api.checkouts.new.path({
      funnel_slug: funnel.slug,
      offer_slug: selectedOfferSlug,
    })
  }

  $: checkoutURL = generateCheckoutURL(selectedOfferSlug)

  $: landingPageCheckoutURL = checkoutURL

  onMount(() => {
    document.addEventListener('upsellery_analytics_updated', () => {
      checkoutURL = generateCheckoutURL(selectedOfferSlug)
    })
  })

  async function redirectToCheckoutUrl() {
    await Tracker.track('LP order now clicked')
    window.location.href = checkoutURL
  }

  let device_data = null

  function onPayPalLoad(ev) {
    ;({ device_data } = ev.detail)

    Tracker.track('LP Paypal', {
      variant: 'loaded',
    })
  }

  const onPayPalClick = () => {
    Tracker.track('LP Paypal', {
      variant: 'clicked',
    })
  }

  function onPaypalSubmit(ev) {
    const { payload } = ev.detail

    const params = {
      device_data: JSON.stringify(device_data),
      paypal: JSON.stringify(payload),
    }
    Tracker.track('LP Paypal', {
      variant: 'submitted',
    })
    window.location.href = `${checkoutURL}?${encodeURIParams(params)}`
  }

  function onGooglePayLoad(ev) {
    loadingGooglePayButton = false

    device_data ||= ev.detail.deviceData
    Tracker.track('LP Google Pay', {
      variant: 'loaded',
    })
  }

  async function onGooglePayClick(ev) {
    const { paymentsClient, googlePaymentInstance } = ev.detail

    try {
      const paymentDataRequest = googlePaymentInstance.createPaymentDataRequest(
        {
          transactionInfo: {
            currencyCode: 'USD',
            totalPriceStatus: 'ESTIMATED',
            totalPrice: selectedOffer.subtotal_price.amount.toString(),
          },
          emailRequired: true,
          shippingAddressRequired: true,
        },
      )

      const paymentData =
        await paymentsClient.loadPaymentData(paymentDataRequest)
      const result = await googlePaymentInstance.parseResponse(paymentData)

      const params = {
        device_data: JSON.stringify(device_data),
        google_pay: JSON.stringify({ paymentData, result }),
      }
      window.location.href = `${checkoutURL}?${encodeURIParams(params)}`
    } catch (err) {
      if (err.statusCode === 'CANCELED') {
        console.log('Google Pay payment canceled')
        return
      }
      throw err
    }
  }

  function onApplePayLoad(ev) {
    loadingApplePayButton = false

    device_data ||= ev.detail.deviceData
    Tracker.track('LP Apple Pay', {
      variant: 'load',
    })
  }

  async function onApplePayClick(ev) {
    const { applePay } = ev.detail

    const req = applePay.createPaymentRequest({
      total: {
        label: 'FIXD Automotive',
        amount: selectedOffer.subtotal_price.amount.toString(),
      },
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['postalAddress', 'name', 'email'],
    })

    const session = new window.ApplePaySession(3, req)
    session.onvalidatemerchant = async (ev) => {
      try {
        const msession = await applePay.performValidation({
          validationURL: ev.validationURL,
          displayName: 'Upsellery',
        })
        await session.completeMerchantValidation(msession)
      } catch (err) {
        console.error('onvalidatemerchant', err)
        session.abort()
      }
    }
    session.onpaymentauthorized = async (ev) => {
      try {
        const tokenized = await applePay.tokenize({
          token: ev.payment.token,
        })
        const params = {
          device_data: JSON.stringify(device_data),
          apple_pay: JSON.stringify({ payment: ev.payment, tokenized }),
        }
        window.location.href = `${checkoutURL}?${encodeURIParams(params)}`

        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
      } catch (err) {
        console.error('onpaymentauthorized', err)
        try {
          session.completePayment(window.ApplePaySession.STATUS_FAILURE)
        } catch (err2) {
          console.error(err2)
        }
      }
    }

    session.begin()
  }

  const changePackCount = (quantity) => {
    selectedQuantity = quantity
    switch (quantity) {
      case '1pk':
        images[0] = { url: SingleSensor, altText: 'Single Sensor' }
        images2[0] = { url: SingleSensorImg, altText: 'Single Sensor' }
        break
      case '2pk':
        images[0] = { url: DoubleSensorImg }
        images2[0] = { url: DoubleSensorImg }
        break
      case '3pk':
        images[0] = { url: TripleSensorImg }
        images2[0] = { url: TripleSensorImg }
        break

      default:
        images[0] = { url: SingleSensor, altText: 'Single Sensor' }
        images2[0] = { url: SingleSensorImg, altText: 'Single Sensor' }
        break
    }
  }
</script>

<style>
  .quantity-button {
    background-color: #f6f6f6;
    width: 24px;
    height: 100%;
  }

  .custom-button {
    padding-left: 0;
    padding-right: 0;
  }

  .yellow-green {
    background: linear-gradient(
      100deg,
      rgb(255 204 0 / 100%) 50%,
      rgb(37 174 78 / 100%) 50%
    );
  }
</style>

<div
  class="px-2.5 rounded-2xl text-left font-inter py-5 md:font-montserrat buy-box"
  bind:this={buyboxElement}
>
  <div class="md:flex">
    <div class="items-center basis-1/2">
      <Experiment name="LP-2024-09-12-buybox-text" let:value>
        {#if value === 'Secure My 67% Discount'}
          <div class="w-full h-inherit mb-4 md:mb-0 md:mr-0">
            <div class="hidden md:block font-inter">
              <ThumbnailImages images={images2} fullImage={true} />
            </div>
            <div class="md:hidden">
              <Carousel showPagination={false} showArrows={true}>
                {#each images2 as item}
                  <CarouselItem>
                    <div class="flex items-center justify-center h-full">
                      <img
                        src={item.url}
                        alt={item.altText}
                        class="w-3/5 {item.altText === 'Single Sensor'
                          ? 'md:w-full'
                          : 'md:w-4/5'}"
                      />
                    </div>
                  </CarouselItem>
                {/each}
              </Carousel>
            </div>
          </div>
        {:else}
          <div class="w-full h-inherit mb-4 md:mb-0 md:mr-0">
            <div class="hidden md:block font-inter">
              <ThumbnailImages {images} />
            </div>
            <div class="md:hidden">
              <Carousel showPagination={false} showArrows={true}>
                {#each images as item}
                  <CarouselItem>
                    <div class="flex items-center justify-center h-full">
                      <img
                        src={item.url}
                        alt={item.altText}
                        class="w-3/5 {item.altText === 'Single Sensor'
                          ? ''
                          : 'md:w-4/5'}"
                      />
                    </div>
                  </CarouselItem>
                {/each}
              </Carousel>
            </div>
          </div>
        {/if}
      </Experiment>
      <div class="md:hidden">
        <h2 class="semi-bold">FIXD Sensor</h2>
        <p class="leading-5">
          OBD2 Professional Bluetooth Scan Tool & Code Reader for iPhone and
          Android + <br />
          <span class="font-bold">Free 14-Day Premium Trial</span>
        </p>
        <div class="flex justify-between my-1">
          <p class="text-grey text-sm">1400+ sold in the last 24 hours</p>
          <div class="flex items-center">
            <div class="flex space-x-0.5">
              {#each new Array(5) as _}
                <StarFilledRound color="yellow" size="xs" />
              {/each}
            </div>
            <div class="ml-1">
              <p class="font-bold text-xs text-black-1">12K</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-2 md:hidden">
        <div
          class="flex flex-wrap items-center leading-6 gap-1 justify-between"
        >
          <div class="flex items-center">
            <span class="text-2xl text-green font-semibold mr-1">
              {new Money(selectedOffer.subtotal_price).toString()}
            </span>
            <span class="strikethrough text-red md:hidden">
              reg ${selectedOfferStrikethroughPrice.amount}
            </span>
          </div>
          <div
            class="bg-yellow text-black-1 py-1 pl-2 pr-4 rounded-sm relative overflow-hidden"
          >
            <p class="text-10 font-bold leading-tight">
              #1 Best Seller in Car Diagnostics
            </p>
            <div
              class="absolute top-0 right-[-14px] bg-white h-[21px] w-[21px] rotate-45"
            />
          </div>
        </div>
      </div>

      <div class="border-2 border-t-0 border-green md:hidden">
        <div class="bg-green px-2 py-0.5">
          <div class="flex items-center text-white">
            <p class="text-white italic font-semibold text-sm">
              Lightning Deal
            </p>
            <div class="mx-2">|</div>
            <div class="relative flex items-center">
              <div class="h-[6px] rounded-l-2xl bg-yellow w-[54px]" />
              <div class="absolute top-[-11px] right-2">
                <Timer color="green" size="sm" />
              </div>
              <div class="h-[6px] rounded-r-2xl bg-white w-[10px]" />
            </div>
            <p class="text-sm pl-2">Ending Soon</p>
          </div>
        </div>
        <div class="pl-2 space-y-1 py-2">
          <div class="font-montserrat">
            <p class="font-bold mb-1">What's Included:</p>

            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">
                Detailed Diagnostic Scan Report <span class="text-red font-bold"
                  >($150+ value)</span
                >
              </p>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">Automatic Maintenance Alerts</p>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">Repair Shop Finder</p>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">Confirmed Fix & Cost estimates</p>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">
                FIXD Mechanic Hotline <span class="text-red font-bold"
                  >($40+ value)</span
                >
              </p>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">Issue Forecast</p>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">
                Used Vehicle History Report <span class="text-red font-bold"
                  >($40+ value)</span
                >
              </p>
            </div>
            <div class="flex items-center gap-2">
              <div
                class="bg-green size-4 rounded flex items-center justify-center"
              >
                <CheckmarkFilled color="green" size="sm" />
              </div>
              <p class="font-medium">Emissions Precheck</p>
            </div>
          </div>
          <div>
            <div class="flex items-center">
              <p class="font-bold pr-2">Qty</p>
              <div
                class="flex items-center font-roboto font-bold border border-[#dbdbdb] h-8"
              >
                <button
                  class="bare quantity-button"
                  disabled={selectedQuantity === '1pk'}
                  class:text-grey={selectedQuantity === '1pk'}
                  on:click={() => {
                    switch (selectedQuantity) {
                      case '3pk':
                        changePackCount('2pk')
                        break
                      case '2pk':
                        changePackCount('1pk')
                        break
                      default:
                        changePackCount('1pk')
                        break
                    }
                  }}>—</button
                >
                <div class="px-4 border-[#dbdbdb] h-full border-x pt-[1px]">
                  <p class="text-lg">{selectedQuantity[0]}</p>
                </div>
                <button
                  disabled={selectedQuantity === '3pk'}
                  class:text-grey={selectedQuantity === '3pk'}
                  class="bare quantity-button"
                  on:click={() => {
                    switch (selectedQuantity) {
                      case '1pk':
                        changePackCount('2pk')
                        break
                      case '2pk':
                        changePackCount('3pk')
                        break
                      default:
                        changePackCount('2pk')
                        break
                    }
                  }}>+</button
                >
              </div>
              <div class="text-green flex items-center pl-2">
                <Hourglass color="green" size="sm" />
                <p class="text-sm pl-1">Only a few left at this price!</p>
              </div>
            </div>
          </div>
          {#if allowedPacks.has('w')}
            <div>
              <p class="text-xs font-bold mb-1">Upgrade Your Protection Plan</p>
              <div class="flex items-center flex-row gap-3">
                <div class="mr-1.5">
                  <input
                    type="checkbox"
                    class="bg-grey-300 border-0 outline-none"
                    use:clickOrEnterHandler={(event) => {
                      event.target.checked
                        ? (selectedPack = 'w')
                        : (selectedPack = '')
                    }}
                  />
                  <span class="text-xs"
                    >Lifetime Warranty - <span class="font-bold"
                      >{warrantyPrice.toString()}</span
                    ></span
                  >
                </div>
              </div>
            </div>
          {/if}
        </div>

        <img
          class="h-[35px] hidden md:block"
          src={ccIconsUrl}
          alt="We accept Visa, MasterCard, American Express, and Paypal"
        />
      </div>
    </div>

    <div class="basis-1/2">
      <div class="description hidden md:block">
        <div class="font-inter xl:w-4/5">
          <div class="font-inter">
            <h2 class="semi-bold">FIXD Sensor</h2>
            <p class="leading-tight">
              OBD2 Professional Bluetooth Scan Tool & Code Reader for iPhone and
              Android + <span class="font-bold">Free 14-Day Premium Trial</span>
            </p>
            <div class="flex justify-between my-1">
              <p class="text-grey">1400+ sold in the last 24 hours</p>
            </div>

            <div
              class="bg-yellow text-black-1 py-1 pl-2 pr-5 rounded-sm relative inline-flex mb-2 overflow-hidden"
            >
              <p class="text-sm font-bold leading-tight">
                #1 Best Seller in Car Diagnostics
              </p>
              <div
                class="absolute top-0 right-[-17px] bg-white h-[26px] w-[26px] rotate-45"
              />
            </div>

            <div class="flex items-center">
              <span class="text-2xl text-green font-semibold mr-1"
                >{new Money(selectedOffer.subtotal_price).toString()}</span
              >
              <span class="strikethrough text-red"
                >reg ${selectedOfferStrikethroughPrice.amount}</span
              >
            </div>
          </div>

          <div class="flex font-inter items-center leading-tight mb-4">
            <div class="bg-yellow py-1 pl-4 pr-2 h-8 rounded-l">
              <p class="uppercase font-bold text-10 text-center">
                Lightning <br /> deal
              </p>
            </div>
            <div
              class="flex items-center justify-center yellow-green py-2 px-1 -mx-1"
            >
              <Lightning color="white" size="sm" />
            </div>
            <div
              class="bg-green flex gap-2 items-center pl-1.5 h-8 w-full rounded-r"
            >
              <p class="text-white my-0 text-center font-semibold text-sm">
                67% OFF + FREE SHIPPING + FREE WARRANTY
              </p>
            </div>
          </div>

          <div class="border-2 border-t-0 border-green">
            <div class="bg-green px-2 py-0.5">
              <div class="flex items-center text-white">
                <p class="text-white italic font-semibold text-sm">
                  Lightning Deal
                </p>
                <div class="mx-2">|</div>
                <div class="relative flex items-center">
                  <div class="h-[6px] rounded-l-2xl bg-yellow w-[54px]" />
                  <div class="absolute top-[-11px] right-2">
                    <Timer color="green" size="sm" />
                  </div>
                  <div class="h-[6px] rounded-r-2xl bg-white w-[10px]" />
                </div>
                <p class="text-sm pl-2">Ending Soon</p>
              </div>
            </div>
            <div class="pl-2 space-y-1 py-2">
              <div class="font-montserrat">
                <p class="font-bold mb-1">What's Included:</p>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">
                    Detailed Diagnostic Scan Report <span
                      class="text-red font-bold">($150+ value)</span
                    >
                  </p>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">Automatic Maintenance Alerts</p>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">Repair Shop Finder</p>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">Confirmed Fix & Cost estimates</p>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">
                    FIXD Mechanic Hotline <span class="text-red font-bold"
                      >($40+ value)</span
                    >
                  </p>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">Issue Forecast</p>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">
                    Used Vehicle History Report <span class="text-red font-bold"
                      >($40+ value)</span
                    >
                  </p>
                </div>
                <div class="flex items-center gap-2">
                  <div
                    class="bg-green size-4 rounded flex items-center justify-center"
                  >
                    <CheckmarkFilled color="green" size="sm" />
                  </div>
                  <p class="font-medium">Emissions Precheck</p>
                </div>
              </div>
              <div>
                <div class="flex items-center">
                  <p class="font-bold pr-3">Qty</p>
                  <div
                    class="flex items-center font-roboto font-bold border border-[#dbdbdb] h-8"
                  >
                    <button
                      class="bare quantity-button"
                      disabled={selectedQuantity === '1pk'}
                      class:text-grey={selectedQuantity === '1pk'}
                      on:click={() => {
                        switch (selectedQuantity) {
                          case '3pk':
                            changePackCount('2pk')
                            break
                          case '2pk':
                            changePackCount('1pk')
                            break
                          default:
                            changePackCount('1pk')
                            break
                        }
                      }}>—</button
                    >
                    <div class="px-4 border-[#dbdbdb] h-full border-x pt-[1px]">
                      <p class="text-lg">{selectedQuantity[0]}</p>
                    </div>
                    <button
                      disabled={selectedQuantity === '3pk'}
                      class:text-grey={selectedQuantity === '3pk'}
                      class="bare quantity-button"
                      on:click={() => {
                        switch (selectedQuantity) {
                          case '1pk':
                            changePackCount('2pk')
                            break
                          case '2pk':
                            changePackCount('3pk')
                            break
                          default:
                            changePackCount('2pk')
                            break
                        }
                      }}>+</button
                    >
                  </div>
                  <div class="text-green flex items-center pl-4">
                    <Hourglass color="green" size="sm" />
                    <p class="text-sm pl-2">Only a few left at this price!</p>
                  </div>
                </div>
              </div>
              {#if allowedPacks.has('w')}
                <div>
                  <p class="text-xs font-bold mb-1">
                    Upgrade Your Protection Plan
                  </p>
                  <div class="flex items-center flex-row gap-3">
                    <div class="mr-1.5">
                      <input
                        type="checkbox"
                        class="bg-grey-300 border-0 outline-none"
                        use:clickOrEnterHandler={(event) => {
                          event.target.checked
                            ? (selectedPack = 'w')
                            : (selectedPack = '')
                        }}
                      />
                      <span class="text-xs"
                        >Lifetime Warranty - <span class="font-bold"
                          >{warrantyPrice.toString()}</span
                        ></span
                      >
                    </div>
                  </div>
                </div>
              {/if}
            </div>
          </div>
        </div>
      </div>

      <div class="w-full xl:w-4/5">
        <div class="my-2 w-full">
          <Carousel
            showPagination={false}
            loop={true}
            splideOptions={{
              perPage: 1,
              autoplay: true,
              interval: 3000,
            }}
          >
            <CarouselItem>
              <div class="flex items-center justify-center gap-1 w-full">
                <Fire color="green" size="sm" />
                <p class="text-green text-xs">
                  Best seller for the past 30 days
                </p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="flex items-center justify-center gap-1 w-full">
                <Hourglass color="green" size="sm" />
                <p class="text-green text-xs">Selling fast! Get yours today!</p>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="flex items-center justify-center gap-1 w-full">
                <CheckmarkFilled color="green" size="sm" />
                <p class="text-green text-xs">
                  Recently, 74 people gave this a 5 star rating
                </p>
              </div>
            </CarouselItem>
          </Carousel>
        </div>

        <div class="mt-3 md:px-0 md:mt-0 order-button">
          <div class="gap-3 md:block items-center w-full space-y-2">
            <div class="flex-1">
              <Experiment name="LP-2024-09-12-buybox-text" let:value>
                <div class="w-full">
                  <button
                    class="rounded-button custom-button"
                    on:click={() => redirectToCheckoutUrl()}
                  >
                    {#if value === 'Secure My 67% Discount'}
                      Secure My 67% Discount
                    {:else}
                      Order Now
                    {/if}
                  </button>
                </div>
              </Experiment>
            </div>

            <div class="flex flex-col sm:flex-row gap-1">
              {#if enabledExpressCheckouts.has('paypal')}
                <div class="relative min-h-[50px] flex-1">
                  <div
                    class="absolute z-20 top-0 left-0 h-[50px] w-full"
                    class:hidden={!loadingPaypalButton}
                  >
                    <Skeleton height="50" shape="pill" />
                  </div>

                  <PaypalButton
                    shape="pill"
                    bind:loading={loadingPaypalButton}
                    height={50}
                    extraClasses="min-h-[50px]"
                    tokenizationKey={gateway.tokenization_key}
                    on:click={onPayPalClick}
                    on:load={onPayPalLoad}
                    on:submit={onPaypalSubmit}
                    on:mockCheckout={() => console.log('Mock PayPal checkout.')}
                  />
                </div>
              {/if}

              <Experiment name="C0-2024-08-30-lp-express-checkouts" let:value>
                {#if value === 'On' && enabledExpressCheckouts.has('google_pay') && $expressButtonsState.google_pay !== false}
                  <div class="relative min-h-[50px] flex-1">
                    <div
                      class="absolute z-20 top-0 left-0 h-[50px] w-full"
                      class:hidden={!loadingGooglePayButton}
                    >
                      <Skeleton height="50" shape="pill" />
                    </div>

                    <GooglePayButton
                      tokenizationKey={gateway.tokenization_key}
                      radius={100}
                      on:load={onGooglePayLoad}
                      on:click={onGooglePayClick}
                    />
                  </div>
                {/if}

                {#if value === 'On' && enabledExpressCheckouts.has('apple_pay') && $expressButtonsState.apple_pay !== false}
                  <div class="relative min-h-[50px] flex-1">
                    <div
                      class="absolute z-20 top-0 left-0 h-[50px] w-full"
                      class:hidden={!loadingApplePayButton}
                    >
                      <Skeleton height="50" shape="pill" />
                    </div>

                    <ApplePayButton
                      tokenizationKey={gateway.tokenization_key}
                      pill={true}
                      on:load={onApplePayLoad}
                      on:click={onApplePayClick}
                    />
                  </div>
                {/if}
              </Experiment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
